import React from 'react';
import { JobDetailsListItem } from '../JobDetailsListItem';
import { Icon, Text } from '@medely/ui-kit';

type ChartingReminderProps = {
  smallIcon?: boolean;
};

export const ChartingReminder = ({ smallIcon = false }: ChartingReminderProps) => {
  return (
    <JobDetailsListItem
      title="Charting"
      icon={<Icon name="light-clipboard-check" iconSize={smallIcon ? '14px' : '20px'} />}
    >
      <Text size="m" color="text.secondary">
        Please complete all patient charts by the end of your shift to prevent payout delays.
      </Text>
    </JobDetailsListItem>
  );
};
